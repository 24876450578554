"use client"

import styles from "./page.module.css";
import dynamic from "next/dynamic";

// Load nextui dynamically
const Avatar = dynamic(() => import('@nextui-org/avatar').then(m => m.Avatar), { loading: () => <p>Laster inn bilde...</p> })
const Button = dynamic(() => import('@nextui-org/button').then(m => m.Button), { loading: () => <a>Laster inn knapp...</a> })

import Image from "next/image";
import Link from "next/link";

import Header from "~/components/header";
import SmallCard from "~/components/cards/small";
import LargeCard from "~/components/cards/large";

import Husly from "./husly/images/husly2-50vw.jpg";
import Kysten from "./images/vestland.jpg";
import Oy from "./images/boroya.jpg";
import Leponex from "./images/wbc_diff.png";
import Lasse from "./images/lasse.jpg";

import Puzzle from "./images/puzzle.svg";
import Hands from "./images/hands.svg?url";
import Politiet from "./images/politiet.svg?url";

import IconPeople from "./images/meeting.svg";
import IconSparkles from "./images/sparkles.svg";
import IconShield from "./images/shield.svg";
import staticImageLoader from "~/loaders/static-image-loader";
import SocialShare from "~/components/metadata";

export default function Home() {
	return (
		<>
			<>
				<title>Heldøgn Bo- og Omsorgstjenester | Ascendi</title>
				<meta name="description" content="Vi tilbyr bo- og omsorgstjenester til de opplever utfordrende atferd i sammenheng med underliggende psykiske og somatiske lidelser, ROP eller utviklingshemning"/>
				<link rel="canonical" href="https://www.ascendi.no/"/>
				<SocialShare/>
			</>
			<Header/>
			<section id="tjenester" className={styles.services}>
				<SmallCard icon={IconPeople} title="Miljøterapi">
					Vi fokuserer på å forstå og håndtere utfordrende atferd, 
					og gir beboeren en mulighet til mestring på en konstruktiv måte.
				</SmallCard>
				<SmallCard icon={IconSparkles} title="Medisinsk Oppfølging">
					Vi samarbeider med spesialist-helsetjenesten og følger opp medisinering <span className="font-bold">inhouse</span>, 
					slik at beboerne slipper unødvendige reiser til sykehuset.
				</SmallCard>
				<SmallCard icon={IconShield} title="Rus og Psykiatri (ROP)">
					Vi jobber med pasienter som sliter med rus, og som også har psykiske problemer. 
					Spesielt flinke er vi med dobbeltdiagnosen rus og schizofreni.
				</SmallCard>
				<div className="md:col-span-3 md:py-16 p-2 bg-card">
					<div className="md:grid-cols-2 grid lg:gap-20 gap-8 mx-auto py-12 max-w-screen-2xl">
						<div className="md:col-start-2 flex flex-col select-none w-full justify-center relative max-w-lg mx-auto text-foreground">
							<Puzzle/>
						</div>
						<div className="md:row-start-1 max-w-lg flex flex-col justify-center mx-auto">
							<h2 className="text-foreground text-2xl font-bold pb-6">Tilpasset omsorg</h2>
							<p className="text-foreground pb-4">
								Ascendi er et sted hvor våre beboere kan føle seg akseptert og forstått uansett bakgrunn. 
								Vi forstår hvor viktig det er med egenart når det gjelder trivsel og utvikling. 
								Derfor tilpasser vi omsorgen og støtten individuelt.
							</p>
							<p className="text-foreground pb-4">
								Miljøterapeutiskt fokuserer vi på å forstå og håndtere den utfordrende atferden, og gir beboeren en mulighet til å mestre dette på konstruktiv måte. 
								Vi samarbeider nært med pårørende, primær- og spesialisthelsetjenesten og jobbspesialist IPS for å utvikle skreddersydde løsninger.
							</p>
							<Button 
								className="mt-8 mx-auto max-w-64 w-1/2 font-semibold"
								as={Link}
								href="https://www.ascendi.no/blog/malrettet-miljoarbeid/"
								color="warning"
								radius="md"
							>
								Finn ut mer
							</Button>
						</div>
					</div>
				</div>
				<LargeCard subtitle="Bosenter" title="Husly" img={Husly}>
					<p>
						Vi holder til på Husly bosenter, kun 30 minutter unna Hamar. 
						Her er det 12 beboerom med dusj og toalett. 
						Vi har fagpersonale døgnet rundt, og pårørende er velkomne til overnattingsbesøk etter avtale.
					</p>
				</LargeCard>
				<LargeCard subtitle="Blindleia/Lillesand" title="Privat Øy" img={Oy}>
					<p>
						Camping, fiske og bading. Alt som hører til sommeren kan oppleves i vårt ferieparadis på sørlandet. 
					</p>
				</LargeCard>
				<LargeCard subtitle="Småbruk på" title="Vestkysten" img={Kysten}>
					<p>
						Det er også mulig å feriere på vestlandet, der vi har en enebolig med fire soverom.
						Vi har badebrygge, fin strandlinje og vår egen båt.
					</p>
				</LargeCard>
				<div className="md:col-span-3 grid lg:grid-cols-2 gap-3">
					<div className="flex justify-center relative bg-card overflow-hidden">
						<Image
							src={Leponex.src}
							width={Leponex.width}
							height={Leponex.height}
							className="select-none py-20 w-full max-w-lg"
							alt={"Bilde av vår HemoCue WBC DIFF maskin."}
							sizes="(max-width: 768px) 100vw, 25vw"
							loader={staticImageLoader}
							style={{
								objectFit: "contain",
							}}
						/>
					</div>
					<div className="flex py-20 md:bg-card">
						<div className="max-w-lg flex-col lg:mx-4 mx-auto">
							<h2 className="text-foreground text-2xl font-bold pb-6">Medisinsk oppfølging</h2>
							<p className="text-foreground pb-4">
								Ascendi legger vekt på legemiddelhåndtering for våre beboere. Vårt team består av sykepleiere, 
								helsefagarbeidere og en erfaren overlege tilknyttet vårt botilbud.  Vi samarbeider med 
								spesialisthelsetjenesten og følger opp medisinering <span>inhouse</span>, slik at beboerne slipper 
								unødvendige reiser til sykehuset.			
							</p>
							<p className="text-foreground pb-4">
								Dette muliggjør at legene med sektoransvar i spesialisthelsetjenesten kan ta gode vurderinger og tilpasse behandlingen.
							</p>
							<p className="text-foreground pb-4">
								Vårt team passer på at alle medisinske rutiner følges nøye. Vi sørger for at medisiner tas som de skal, sjekker blodverdier jevnlig, og følger nøye med på hvordan medisinen virker og eventuelle bivirkninger.
							</p>
							<p className="text-foreground pb-4">
								Vi tilbyr blant annet oppfølging &quot;hjemme&quot; på botilbudet med kapillære blodprøver med en HemoCue® WBC DIFF Analyzer. 
								Instrumentet, med sin enkelhet og sin nøyaktighet, er unikt for pasientnær leukocyttelling. 
								Vi bruker dette instrumentet for pasienter i oppstartsfasen av Leponex og fentiazinderivater og i videre oppfølging av medisiner. 
							</p>
						</div>
					</div>
				</div>
			</section>
			<section id="quote" className="py-12 bg-dark">
				<div className="max-w-screen-xl mx-auto"
					style={{
						display:"flex",
						justifyContent:"center",
						flexDirection:"column",
						textAlign:"center",
					}}
				>
					<h3 className="text-xl text-white lg:text-2xl lg:w-3/4 mx-auto p-1">Vi bygger selvtillit. Vi tror på å skape en trygg og stabil hverdag som fremmer personlig utvikling og velvære.</h3>
					<p className="text-white/60 font-light">- Lasse Toroczkay, psykiatrisk sykepleier på Ascendi</p>
					<Avatar className="mx-auto mt-4 w-16 h-16 text-large"
						src={`${Lasse.src}?w=64`}
						name="LT"
						color="danger"
						// size="lg"
						isBordered
					/>
				</div>
			</section>
			{/* <section id="sikkerhet" className="px-4 bg-light">
				<div className={`${styles.section} max-w-screen-xl mx-auto md:grid-cols-4 grid-cols-1 grid gap-10`}>
					<div className="md:col-span-2 flex flex-col justify-center">
						<h2 className="text-black text-2xl font-bold pb-6">En trygg og stabil hverdag</h2>
						<p className="text-black pb-4">
							Vi evaluerer og forbedrer sikkerhetstiltak som inkluderer beredskapsprosedyrer, 
							ansatte som gjennomgår grundig opplæring og jevnlige risikovurderinger.
						</p>
						<p className="text-black pb-6">
							I vår tilnærming legger vi vekt på kvalifisert og kompetent personale som er i stand til å håndtere utfordrende atferd. 
							Vår personalgruppe er håndplukket av ledelsen, som jobber i team med erfaring og kompetanse innen denne spesifikke domenen.
						</p>
						
						<p className="text-black pb-6">
							En sentral del av å sikre både beboernes og personalets velvære er evnen til å analysere og unngå utfordrende situasjoner. 
						</p>
						<p className="text-black pb-6">
							Vi styrker personalets utholdenhet og evne til å levere omsorg i krevende situasjoner. 
							Vi opprettholder en åpen og jevnlig kommunikasjon med alle for å håndtere og løse eventuelle sikkerhetsbekymringer som oppstår. 
						</p>
						<p className="text-black pb-8">
							Vi har etablert et samarbeid med politiet i Ringsaker for å sikre at samfunnsvernet ivaretas på en effektiv måte.
						</p>
						<Link href="https://www.politiet.no/om-politiet/organisasjonen/politidistrikter/innlandet/brumunddal-politistasjon/">
							<Image
								src={Politiet.src}
								alt={"politiet.no"}
								className="rounded fill-white select-none max-w-60 mx-auto pt-4"
								height={Politiet.height}
								width={Politiet.width}
							/>
						</Link>
					</div>
					<div className="md:col-span-2 flex flex-col justify-center relative">
						<Image
							src={Hands.src}
							alt={""}
							className="rounded fill-white select-none max-sm:pt-20 px-8"
							height={Hands.height}
							width={Hands.width}
							sizes="50vw"
						/>
					</div>
				</div>
			</section> */}
			<section id="kontakt" className="px-4 bg-light">
				<div className="max-w-screen-xl m-auto col-span-2 flex flex-col justify-center text-center py-14">
					<h2 className="text-black text-2xl font-bold pb-6">Spørsmål? Ta kontakt!</h2>
					<div className="pb-6">
						<p className="text-black pb-3">
							Ta kontakt med daglig leder, Lasse Toroczkay, på telefon:
						</p>
						<a className="text-black mx-auto text-lg font-semibold" href="tel:+4798082082">98 082 082</a>
					</div>
					<div className="pb-6">
						<p className="text-black pb-3">
							For akutt bistand er vakttelefonen døgnåpen:
						</p>
						<a className="text-black mx-auto text-lg font-semibold" href="tel:+4722330100">223 30 100</a>
					</div>
					<div className="text-black pb-2">
						<p>
							For skriftlig henvendelser send en e-post til <a className="font-medium" href="mailto:sjefen@ascendi.no">sjefen@ascendi.no</a>. 
						</p>
					</div>
					<div className="text-black pb-10">
						<p>
							For generelle henvendelser send en e-post til <a className="font-medium" href="mailto:hei@ascendi.no">hei@ascendi.no</a>.
						</p>
					</div>
				</div>
			</section>
		</>
	);
}
